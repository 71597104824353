import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['pan', 'exp', 'cvv', 'copyCard', 'copyExp', 'copyCvv']

  async connect () {
    console.log('Impact card controller connected')
    await this.initializeMarqeta()
  }

  async initializeMarqeta () {
    try {
      const token = await this.fetchClientAccessToken()

      if (token) {
        await this.loadMarqetaJs()
        marqeta.bootstrap({
          clientAccessToken: token,
          component: {
            showPan: {
              cardPan: {
                domId: this.panTarget.id,
                format: true,
                styles: {
                  span: {
                    color: '#333333',
                    'font-family': 'Helvetica',
                    'font-size': '1.2rem'
                  }
                }
              },
              copyCardPan: {
                domId: this.copyCardTarget.id,
                mode: 'transparent',
                onCopySuccess: () => {
                  const copyText = document.getElementById('copy-card-text')
                  copyText.textContent = 'Copied Card Number to your clipboard!'
                  copyText.classList.remove('hidden')
                  setTimeout(() => {
                    copyText.classList.add('hidden')
                    copyText.textContent = 'Copy Card Number to your clipboard'
                  }, 1000)
                },
                onCopyFailure: (error) => console.error('Copy Card Number failed:', error)
              },
              cardExp: {
                domId: this.expTarget.id,
                format: true,
                styles: {
                  span: {
                    color: '#333333',
                    'font-family': 'Helvetica',
                    'font-size': '1.2rem'
                  }
                }
              },
              copyCardExp: {
                domId: this.copyExpTarget.id,
                mode: 'transparent',
                onCopySuccess: () => {
                  const copyText = document.getElementById('copy-exp-text')
                  copyText.textContent = 'Copied expiration date to your clipboard!'
                  copyText.classList.remove('hidden')
                  setTimeout(() => {
                    copyText.classList.add('hidden')
                    copyText.textContent = 'Copy expiry date to your clipboard'
                  }, 1000)
                },
                onCopyFailure: (error) => console.error('Copy expiration date failed:', error)
              },
              cardCvv: {
                domId: this.cvvTarget.id,
                styles: {
                  span: {
                    color: '#333333',
                    'font-family': 'Helvetica',
                    'font-size': '1.2rem'
                  }
                }
              },
              copyCardCvv: {
                domId: this.copyCvvTarget.id,
                mode: 'transparent',
                onCopySuccess: () => {
                  const copyText = document.getElementById('copy-cvv-text')
                  copyText.textContent = 'Copied CVV to your clipboard'
                  copyText.classList.remove('hidden')
                  setTimeout(() => {
                    copyText.classList.add('hidden')
                    copyText.textContent = 'Copy CVV to your clipboard'
                  }, 1000)
                },
                onCopyFailure: (error) => console.error('Copy CVV failed:', error)
              }
            }
          },
          callbackEvents: {
            onSuccess: () => console.log('Marqeta initialized successfully'),
            onFailure: (response) => console.error('Marqeta initialization failed:', response)
          }
        })
      } else {
        console.error('Token retrieval failed: Marqeta initialization aborted')
      }
    } catch (error) {
      console.error('Marqeta initialization error:', error)
    }
  }

  async fetchClientAccessToken () {
    try {
      const response = await fetch('/impact_cards/fetch_token', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
      const data = await response.json()

      if (response.ok) {
        return data.clientAccessToken
      } else {
        console.error('Failed to retrieve access token:', data.error)
        return null
      }
    } catch (error) {
      console.error('Error fetching access token:', error)
      return null
    }
  }

  loadMarqetaJs () {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script')
      script.src = 'https://widgets.marqeta.com/marqetajs/2.0.0/marqeta.min.js'
      script.type = 'text/javascript'
      script.onload = resolve
      script.onerror = () => {
        console.error('Failed to load Marqeta JavaScript')
        reject(new Error('Failed to load Marqeta JavaScript'))
      }
      document.head.appendChild(script)
    })
  }
}
