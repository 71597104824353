import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'firstName', 'lastName', 'street1', 'city', 'state', 'zip', 'phoneNumber', 'errorMessage', 'errorBanner']

  connect () {
    this.clearErrorOnInput()
  }

  validate (event) {
    event.preventDefault()
    this.clearErrors()

    const validations = [
      { target: this.stateTarget, validator: this.isRequired, message: 'State is required.' },
      { target: this.firstNameTarget, validator: this.isRequired, message: 'First name is required.' },
      { target: this.lastNameTarget, validator: this.isRequired, message: 'Last name is required.' },
      { target: this.street1Target, validator: this.validateStreet1.bind(this) },
      { target: this.cityTarget, validator: this.validateCity.bind(this) },
      { target: this.zipTarget, validator: this.validateZip.bind(this) },
      { target: this.phoneNumberTarget, validator: this.validatePhoneNumber.bind(this) }
    ]

    let allValid = true

    validations.forEach(({ target, validator, message }) => {
      const error = validator(target.value)
      if (error) {
        this.showError(target, message || error)
        allValid = false
      }
    })

    if (allValid) {
      this.formTarget.submit()
    }
  }

  closeBanner (event) {
    event.preventDefault()
    this.errorBannerTarget.classList.remove('d-flex')
  }

  showError (element, message) {
    const error = document.createElement('p')
    error.className = 'text-danger padding-0 margin-0 font-size-base'
    error.textContent = message
    element.classList.add('is-invalid')
    element.parentNode.insertBefore(error, element.nextSibling)
    this.errorBannerTarget.classList.add('d-flex')
  }

  clearError (element) {
    const error = element.nextElementSibling
    if (error && error.classList.contains('text-danger')) {
      error.remove()
    }
    element.classList.remove('is-invalid')
  }

  clearErrors () {
    this.element.querySelectorAll('.text-danger').forEach(error => error.remove())
    this.errorBannerTarget.classList.remove('d-flex')
  }

  clearErrorOnInput () {
    const inputs = [
      this.firstNameTarget,
      this.lastNameTarget,
      this.street1Target,
      this.cityTarget,
      this.stateTarget,
      this.zipTarget,
      this.phoneNumberTarget
    ]

    inputs.forEach(target => {
      target.addEventListener('input', () => this.clearError(target))
    })
  }

  isRequired (value) {
    return value.trim() === '' ? 'This field is required.' : null
  }

  validateStreet1 (value) {
    if (value.trim() === '') return 'Address is required.'
    if (value.trim().length > 200) return 'Address must not exceed 200 characters.'
    return null
  }

  validateCity (value) {
    const cityPattern = /^[A-Za-z\s]+$/
    if (value.trim() === '') return 'City is required.'
    if (!cityPattern.test(value.trim())) return 'City should contain only alphabets.'
    if (value.trim().length > 100) return 'City must not exceed 100 characters.'
    return null
  }

  validateZip (value) {
    const zipPattern = /^\d{5}(-\d{4})?$/
    if (value.trim() === '') return 'ZIP/Postal code is required.'
    if (!zipPattern.test(value.trim())) return 'Invalid ZIP/Postal code format.'
    return null
  }

  validatePhoneNumber (value) {
    if (value.trim() === '') return 'Mobile Phone Number is required.'
    if (/[^0-9-]/.test(value)) {
      return 'Phone number can only contain digits and dashes (-).'
    }

    const phoneWithoutSpecialChars = value
      .trim()
      .replace(/^\+\d{1,3}/, '')
      .replace(/[-\s]/g, '')

    if (phoneWithoutSpecialChars.length !== 10 || isNaN(phoneWithoutSpecialChars)) {
      return 'Phone number must be exactly 10 digits, excluding the country code.'
    }

    return null
  }
}
