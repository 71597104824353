import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.reissueCard()
  }

  async reissueCard () {
    try {
      const response = await fetch('/impact_card_tokens/fetch_last_four', {
        method: 'GET',
        headers: { 'Accept': 'application/json' }
      })
      if (!response.ok) throw new Error('Network response was not ok')

      const data = await response.json()

      if (data) {
        document.querySelector('#last-four').textContent = `${data.last_four}`
      }
    } catch (error) {
      console.error('Error reissuing card:', error)
    }
  }
}
