import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'errorMessage', 'label', 'errorBanner']

  connect () {
    this.checkboxTarget.addEventListener('change', this.clearErrors.bind(this))
  }

  validate (event) {
    if (!this.checkboxTarget.checked) {
      event.preventDefault()
      this.errorMessageTarget.classList.remove('d-none')
      this.labelTarget.classList.add('text-danger')
      this.errorBannerTarget.classList.add('d-flex')
    } else {
      this.errorMessageTarget.classList.add('d-none')
      this.labelTarget.classList.remove('text-danger')
      this.errorBannerTarget.classList.remove('d-flex')
    }
  }

  clearErrors () {
    if (this.checkboxTarget.checked) {
      this.errorMessageTarget.classList.add('d-none')
      this.labelTarget.classList.remove('text-danger')
      this.errorBannerTarget.classList.remove('d-flex')
    }
  }

  closeBanner (event) {
    event.preventDefault()
    this.errorBannerTarget.classList.remove('d-flex')
  }
}
