import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'disputeAmount',
    'disputeType',
    'billingFields',
    'contactDate',
    'contactName',
    'contactMethod',
    'merchantResponse'
  ]

  connect () {
    this.disputeTypeTarget.addEventListener('change', this.toggleBillingFields.bind(this))
    this.disputeAmountTarget.addEventListener('focusout', this.validateDisputeAmount.bind(this))
    this.clearErrorOnInput()
  }

  validateDisputeAmount () {
    const disputeAmount = parseFloat(this.disputeAmountTarget.value)
    const transactionAmount = parseFloat(this.disputeAmountTarget.dataset.transactionAmount)

    if (disputeAmount > transactionAmount || isNaN(disputeAmount)) {
      this.disputeAmountTarget.classList.add('is-invalid', 'border-red')
      this.disputeAmountTarget.nextSibling.classList.remove('color-gray-600')
      this.disputeAmountTarget.nextSibling.classList.add('text-danger')
      this.disputeAmountTarget.value = transactionAmount
    }
  }

  appendError (element) {
    const error = document.createElement('p')
    error.className = 'text-danger padding-0 margin-0 font-size-base'

    if (element !== this.disputeAmountTarget) {
      error.textContent = 'This field is required'
    }

    element.classList.add('is-invalid', 'border-red')
    element.parentNode.insertBefore(error, element.nextSibling)
  }

  toggleBillingFields () {
    const showBillingFields = [
      'I was charged a higher donation amount',
      'I was charged multiple times for a single donation',
      'I was charged for a recurring donation after I canceled it'
    ].includes(this.disputeTypeTarget.value)
    this.billingFieldsTarget.style.display = showBillingFields ? 'block' : 'none'
  }

  validateForm (event) {
    event.preventDefault()
    let valid = true
    this.clearErrorOnInput()

    const fields = [
      { target: this.disputeAmountTarget },
      { target: this.disputeTypeTarget },
      ...(this.billingFieldsTarget.style.display === 'block' ? [
        { target: this.contactDateTarget },
        { target: this.contactNameTarget },
        { target: this.contactMethodTarget },
        { target: this.merchantResponseTarget }
      ] : [])
    ]

    fields.forEach(field => {
      if (field === this.disputeAmountTarget) {
        this.validateDisputeAmount()
      } else if (field.target.value.trim() === '') {
        this.showError(field.target)
        valid = false
      }
    })

    if (valid) {
      this.element.submit()
    }
  }

  showError (element) {
    this.appendError(element)
  }

  clearError (element) {
    const error = element.nextElementSibling
    if (element === this.disputeAmountTarget) {
      this.disputeAmountTarget.classList.remove('is-invalid', 'border-red')
      this.disputeAmountTarget.nextSibling.classList.add('color-gray-600')
      this.disputeAmountTarget.nextSibling.classList.remove('text-danger')
    } else if (error && error.classList.contains('text-danger')) {
      error.remove()
    }
    element.classList.remove('is-invalid', 'border-red')
  }

  clearErrorOnInput () {
    const fields = [
      this.disputeAmountTarget,
      this.disputeTypeTarget,
      this.contactDateTarget,
      this.contactNameTarget,
      this.contactMethodTarget,
      this.merchantResponseTarget
    ]

    fields.forEach(field => {
      field.addEventListener('input', () => this.clearError(field))
      if (field === this.contactDateTarget) {
        field.addEventListener('focus', () => this.clearError(field))
      }
    })
  }

  checkIfFormIsValid () {
    const allFieldsValid = !this.element.querySelector('.is-invalid')
    if (allFieldsValid) {
      this.element.submit()
    }
  }
}
