import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['appleButton']
  static values = {
    cardToken: String,
    appleScriptUrl: 'https://smp-device-content.apple.com/navweb/asset/initAddToAppleWallet.js',
    appleDomain: 'https://apple-pay.apple.com',
    applePartnerId: String
  }

  connect () {
    this.cardExist = false
    this.isScriptLoaded = false
    this.isWalletInitialized = false
    this.loadApplePayScript()
  }

  loadApplePayScript () {
    if (document.querySelector(`script[src='${this.appleScriptUrlValue}']`)) {
      this.scriptLoaded()
      return
    }

    const script = document.createElement('script')
    script.src = this.appleScriptUrlValue
    script.onload = () => this.scriptLoaded()
    document.head.appendChild(script)
  }

  scriptLoaded () {
    this.isScriptLoaded = true
    console.log('Apple Pay script loaded successfully.')
    this.checkCardUser()
  }

  async checkCardUser () {
    try {
      const response = await fetch('/impact_card_tokens/card_exist', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })

      const data = await response.json()
      if (data.cardExist) {
        this.cardExist = true
        this.addToApplePay()
      }
    } catch (error) {
      console.error('Error checking card existence:', error)
    }
  }

  async addToApplePay() {
    try {
      await this.fetchTokens()

      if (!this.cardTokenValue) {
        console.error('Card token is missing.')
        return
      }

      this.initializeApplePay()
    } catch (error) {
      console.error('Error initializing Apple Pay:', error)
    }
  }

  initializeApplePay () {
    if (!this.isScriptLoaded) {
      console.error('Apple Pay script not loaded yet.')
      return
    }

    if (this.isWalletInitialized) {
      console.warn('Apple Wallet already initialized.')
      return
    }

    try {
      window.initAddToAppleWallet({
        partnerId: this.applePartnerIdValue,
        domain: this.appleDomainValue,
        buttonId: this.appleButtonTarget.id,
        jwtResolver: this.jwtResolver.bind(this),
        resultResolver: this.handleResult.bind(this)
      })

      this.isWalletInitialized = true
    } catch (error) {
      console.error('Error initializing Apple Wallet:', error)
    }
  }

  async fetchTokens () {
    try {
      const response = await fetch('/impact_card_tokens/fetch_token', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      })

      if (!response.ok) throw new Error(`Fetch error: ${response.statusText}`)

      const data = await response.json()
      this.cardTokenValue = data.cardToken
      this.applePartnerIdValue = data.appleToken
    } catch (error) {
      console.error('Error fetching tokens:', error)
    }
  }

  jwtResolver() {
    return new Promise((resolve, reject) => {
      if (!this.cardTokenValue) {
        return reject(new Error('Missing card token'))
      }

      const payload = { card_token: this.cardTokenValue }
      console.log('Sending payload to backend:', payload)

      fetch('/impact_card_tokens/tokenize_apple_pay', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content
        },
        body: JSON.stringify(payload)
      })
        .then(response => {
          console.log("jwt respone", response)
          if (!response.ok) throw new Error(`Server error: ${response.statusText}`)
          return response.json()
        })
        .then(data => {
          console.log('JWT received:', data)
          resolve(data)
        })
        .catch(error => {
          console.error('Error fetching JWT:', error)
          reject(error)
        })
    })
  }

  handleResult (result) {
    if (result?.status === 200) {
      console.log('Apple Pay Success:', result)
    } else {
      console.error('Apple Pay Error:', result?.message || 'Unknown error')
    }
  }

  // async jwtResolver () {
  //   try {
  //     const response = await fetch('/impact_card_tokens/tokenize_apple_pay', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content
  //       },
  //       body: JSON.stringify({ card_token: this.cardTokenValue })
  //     })
  
  //     if (!response.ok) {
  //       throw new Error(`Error tokenizing Apple Wallet: ${response.statusText}`)
  //     }

  //     const data = await response.json()
  //     console.log("api response data", data)
  //     if (!data.jws || !data.jws.payload) {
  //       throw new Error("Invalid response: Missing payload")
  //     }

  //     const decodedData = this.decodeBase64Json(data.jws.payload)
  //     console.log("decodedData", decodedData)
  //     return decodedData
  //   } catch (error) {
  //     console.error('Error in jwtResolver:', error)
  //     throw error
  //   }
  // }

  // decodeBase64Json(base64String) {
  //   try {
  //     base64String = base64String.replace(/-/g, '+').replace(/_/g, '/')
  //     while (base64String.length % 4 !== 0) {
  //       base64String += '='
  //     }
  //     const decodedText = atob(base64String)
  //     return JSON.parse(decodedText)
  //   } catch (error) {
  //     console.error("Base64 decoding failed:", error)
  //     throw new Error("Invalid Base64 payload")
  //   }
  // }


}
