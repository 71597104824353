import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['paymentElement', 'message', 'spinner', 'submit', 'amountInput', 'creditCommissionPercentage', 'achCommissionPercentage', 'walletAmountLabel', 'commissionAmountLabel', 'commissionCheckbox', 'matchingCheckbox', 'minimumMatchingAmount', 'minimumTxnAmount']

  async initialize () {
    this.updateAmount()
  }

  toggleCommission () {
    this.updateProcessingFee()
  }

  updateAmount () {
    const payingAmount = parseFloat(this.amountInputTarget.value)
    const minimumMatchingAmount = parseFloat(this.minimumMatchingAmountTarget.value)
    const minimumTxnAmount = parseFloat(this.minimumTxnAmountTarget.value)
    const creditCommissionPercentage = parseFloat(this.creditCommissionPercentageTarget.value)

    if (isNaN(payingAmount)) {
      this.showMessage('Please enter a valid amount.')
      this.resetAmountToDefault()
      return
    }

    if (payingAmount < 0.5) {
      this.showMessage('The amount should not be less than $0.5!')
      this.resetAmountToDefault()
      return
    }

    if (payingAmount < minimumTxnAmount) {
      this.submitTarget.disabled = true
      const minimumInfo = this.element.querySelector('.minimum-amount-info')
      if (minimumInfo) {
        minimumInfo.classList.remove('hidden')
      }
    } else {
      this.submitTarget.disabled = false
      const minimumInfo = this.element.querySelector('.minimum-amount-info')
      if (minimumInfo) {
        minimumInfo.classList.add('hidden')
      }
    }

    let commissionAmount = 0
    let totalAmount = payingAmount

    if (this.hasCommissionCheckboxTarget && this.commissionCheckboxTarget.checked) {
      commissionAmount = (payingAmount * creditCommissionPercentage) / 100
      totalAmount += commissionAmount
    }

    if (this.hasCommissionAmountLabelTarget) {
      this.commissionAmountLabelTarget.textContent = `$${commissionAmount.toFixed(2)}`
    }

    if (this.hasWalletAmountLabelTarget) {
      this.walletAmountLabelTarget.textContent = `$${totalAmount.toFixed(2)}`
    }

    if (this.hasMatchingCheckboxTarget) {
      if (payingAmount < minimumMatchingAmount) {
        this.matchingCheckboxTarget.checked = false
        this.matchingCheckboxTarget.disabled = true

        const matchingInfo = this.element.querySelector('.matching-info')
        if (matchingInfo) {
          matchingInfo.classList.remove('hidden')
        }
      } else {
        this.matchingCheckboxTarget.disabled = false
        const matchingInfo = this.element.querySelector('.matching-info')
        if (matchingInfo) {
          matchingInfo.classList.add('hidden')
        }
      }
    }
  }

  resetAmountToDefault () {
    const amountInput = this.amountInputTarget
    if (amountInput) {
      amountInput.value = 0.5
      this.updateAmount()
    }
  }

  showMessage (message) {
    this.messageTarget.textContent = message
    this.messageTarget.classList.remove('hidden')
    setTimeout(() => {
      this.messageTarget.classList.add('hidden')
      this.messageTarget.textContent = ''
    }, 4000)
  }

  setLoading (isLoading) {
    this.submitTarget.disabled = isLoading
    if (isLoading === true) {
      this.submitTarget.classList.add('hidden')
    } else {
      this.submitTarget.classList.remove('hidden')
    }
    this.spinnerTarget.classList.toggle('hidden', !isLoading)
  }

  getAmount () {
    return parseFloat(this.amountInputTarget.value) || 0
  }

  isValidAmount (amount) {
    return !isNaN(amount) && amount > 0
  }
}
