import { Controller } from '@hotwired/stimulus'
import { loadStripe } from '@stripe/stripe-js'

export default class extends Controller {
  static targets = ['paymentElement', 'message', 'spinner', 'submit', 'amountInput', 'creditCommissionPercentage', 'achCommissionPercentage', 'walletAmountLabel', 'commissionAmountLabel', 'commissionCheckbox', 'matchingCheckbox', 'minimumMatchingAmount', 'minimumTxnAmount']
  static returnURL = `${window.location.origin}/wallets/wallets/complete`

  async initialize () {
    this.stripePublicKey = this.element.dataset.stripePublicKey
    this.stripe = await loadStripe(this.stripePublicKey)
    this.recurring = new URLSearchParams(window.location.search).get('recurring') === 'true'
    if (!this.stripe) {
      this.showMessage('Failed to load Stripe.')
      return
    }

    console.log('Stripe has been successfully initialized.')
    this.updateAmount()
    this.createPaymentIntent('51.50', true)
  }

  async setupStripeElements (clientSecret) {
    this.elements = this.stripe.elements({ clientSecret })
    const paymentElement = this.elements.create('payment', {
      fields: { billing_details: { name: 'auto' } }
    })

    paymentElement.on('change', this.updatePayButtonText.bind(this))
    paymentElement.mount(this.paymentElementTarget)
    this.paymentElementTarget.classList.remove('hidden')
  }

  updatePayButtonText (event) {
    const paymentMethod = event.value.type
    if (paymentMethod === 'us_bank_account') {
      this.submitTarget.textContent = 'Pay with Bank Account'
    } else if (paymentMethod === 'card') {
      this.submitTarget.textContent = 'Pay with Credit Card'
    } else {
      this.submitTarget.textContent = 'Pay'
    }
  }

  toggleCommission () {
    this.updateProcessingFee()
  }

  updateAmount () {
    const payingAmount = parseFloat(this.amountInputTarget.value)
    const minimumMatchingAmount = parseFloat(this.minimumMatchingAmountTarget.value)
    const minimumTxnAmount = parseFloat(this.minimumTxnAmountTarget.value)
    const creditCommissionPercentage = parseFloat(this.creditCommissionPercentageTarget.value)

    if (isNaN(payingAmount)) {
      this.showMessage('Please enter a valid amount.')
      this.resetAmountToDefault()
      return
    }

    if (payingAmount < 0.5) {
      this.showMessage('The amount should not be less than $0.5!')
      this.resetAmountToDefault()
      return
    }

    if (payingAmount < minimumTxnAmount) {
      this.submitTarget.disabled = true
      const minimumInfo = this.element.querySelector('.minimum-amount-info')
      if (minimumInfo) {
        minimumInfo.classList.remove('hidden')
      }
    } else {
      this.submitTarget.disabled = false
      const minimumInfo = this.element.querySelector('.minimum-amount-info')
      if (minimumInfo) {
        minimumInfo.classList.add('hidden')
      }
    }

    let commissionAmount = 0
    let totalAmount = payingAmount

    if (this.hasCommissionCheckboxTarget && this.commissionCheckboxTarget.checked) {
      commissionAmount = (payingAmount * creditCommissionPercentage) / 100
      totalAmount += commissionAmount
    }

    if (this.hasCommissionAmountLabelTarget) {
      this.commissionAmountLabelTarget.textContent = `$${commissionAmount.toFixed(2)}`
    }

    if (this.hasWalletAmountLabelTarget) {
      this.walletAmountLabelTarget.textContent = `$${totalAmount.toFixed(2)}`
    }

    if (this.hasMatchingCheckboxTarget) {
      if (payingAmount < minimumMatchingAmount) {
        this.matchingCheckboxTarget.checked = false
        this.matchingCheckboxTarget.disabled = true

        const matchingInfo = this.element.querySelector('.matching-info')
        if (matchingInfo) {
          matchingInfo.classList.remove('hidden')
        }
      } else {
        this.matchingCheckboxTarget.disabled = false
        const matchingInfo = this.element.querySelector('.matching-info')
        if (matchingInfo) {
          matchingInfo.classList.add('hidden')
        }
      }
    }
  }

  calculateTotalAmount () {
    const payingAmount = parseFloat(this.amountInputTarget.value) || 0
    const creditCommissionPercentage = parseFloat(this.creditCommissionPercentageTarget.value) || 0
    let totalAmount = payingAmount

    if (this.hasCommissionCheckboxTarget && this.commissionCheckboxTarget.checked) {
      const commissionAmount = (payingAmount * creditCommissionPercentage) / 100
      totalAmount += commissionAmount
    }

    return totalAmount
  }

  async createPaymentIntent (amount, initializeElements = false) {
    try {
      const clientSecret = await this.fetchPaymentIntentClientSecret(amount)
      if (initializeElements && !this.elements) {
        await this.setupStripeElements(clientSecret)
      }
    } catch (error) {
      this.showMessage('An error occurred while processing your payment. Please try again later.')
      console.error('Payment Intent Error:', error)
    }
  }

  resetAmountToDefault () {
    const amountInput = this.amountInputTarget
    if (amountInput) {
      amountInput.value = 0.5
      this.updateAmount()
    }
  }

  async fetchPaymentIntentClientSecret (amount) {
    const response = await fetch('/wallets/wallets/create_payment_intent', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        items: [{ id: 'custom_item', amount: Math.round(amount * 100) }],
        paymentMethodTypes: ['card', 'us_bank_account'],
        payment_intent_id: this.paymentIntentId || null
      })
    })
    const { clientSecret, paymentIntentId } = await response.json()
    this.paymentIntentId = paymentIntentId
    return clientSecret
  }

  async submit (event) {
    event.preventDefault()
    this.setLoading(true)
    try {
      const totalAmount = this.calculateTotalAmount()
      await this.createPaymentIntent(totalAmount.toFixed(2), false)

      const { error } = await this.confirmPayment()
      if (error) {
        this.showMessage(error.message)
      } else {
        const returnUrl = `${this.constructor.returnURL}?recurring=${this.recurring}`
        window.location.href = returnUrl
      }
    } catch (error) {
      this.showMessage('Payment processing failed.')
    }
    this.setLoading(false)
  }

  async confirmPayment () {
    const userPayCommission = this.hasCommissionCheckboxTarget
      ? this.commissionCheckboxTarget.checked
      : false

    const amount = this.hasAmountInputTarget
      ? this.amountInputTarget.value
      : 0

    const userAllowsMatching = this.hasMatchingCheckboxTarget
      ? this.matchingCheckboxTarget.checked
      : false

    const returnUrl = `${this.constructor.returnURL}?recurring=${this.recurring}&userPayCommission=${userPayCommission}&amount=${amount}&userAllowsMatching=${userAllowsMatching}`

    return this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: { return_url: returnUrl }
    })
  }

  showMessage (message) {
    const flashContainer = document.getElementById('processing-err')
    if (flashContainer) {
      flashContainer.innerHTML = `
        <div>
          ${message}
        </div>`
      setTimeout(() => {
        flashContainer.innerHTML = ''
      }, 5000)
    } else {
      console.error('Flash container not found')
    }

    this.messageTarget.textContent = message
    this.messageTarget.classList.remove('hidden')
    setTimeout(() => {
      this.messageTarget.classList.add('hidden')
      this.messageTarget.textContent = ''
    }, 4000)
  }

  setLoading (isLoading) {
    this.submitTarget.disabled = isLoading
    if (isLoading === true) {
      this.submitTarget.classList.add('hidden')
    } else {
      this.submitTarget.classList.remove('hidden')
    }
    this.spinnerTarget.classList.toggle('hidden', !isLoading)
  }

  getAmount () {
    return parseFloat(this.amountInputTarget.value) || 0
  }

  isValidAmount (amount) {
    return !isNaN(amount) && amount > 0
  }
}
