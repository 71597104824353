import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['banner']

  connect () {
    console.log('CloseBannerController connected')
  }

  close (event) {
    event.preventDefault()

    const banner = document.getElementById(this.element.dataset.bannerId)
    if (banner) {
      banner.remove()
    }

    fetch('/users/update_banner_display', {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        Accept: 'application/json'
      }
    }).then(() => {
      console.log('Banner close action sent to the server')
    }).catch((error) => {
      console.error('Error updating server:', error)
    })
  }
}
